var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EKDialog',{attrs:{"title":" نقاط  بيع جديدة","placeholder":"ابحث عن نقاط  بيع جديدة","btnText":" نقاط  بيع جديدة","endClose":""},on:{"open":function($event){return _vm.$store.commit('Reset_Pos_Dto')},"ok":_vm.onSubmit,"search":_vm.search},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('ValidationObserver',{ref:"observer"},[_c('EKInputText',{attrs:{"rules":[{ type: 'required', message: 'اسم الكامل مطلوب' }],"label":"  الاسم الكامل","placeholder":"ادخل الاسم الكامل","name":"name"},model:{value:(_vm.posDto.name),callback:function ($$v) {_vm.$set(_vm.posDto, "name", $$v)},expression:"posDto.name"}}),_c('EKInputText',{attrs:{"label":"اسم المستخدم","placeholder":"ادخل اسم المستخدم","rules":[
                    {
                        type: 'required',
                        message: 'اسم المستخدم مطلوب'
                    },
                    {
                        type: 'no_spaces',
                        message: 'اسم المستخدم لا يمكن ان يحوي فراغات'
                    },
                    {
                        type: 'english_letters_numbers',
                        message:
                            'اسم المستخدم يجب ان يحوي على احرف انجليزية وأرقام فقط'
                    }
                ],"name":"userName"},model:{value:(_vm.posDto.userName),callback:function ($$v) {_vm.$set(_vm.posDto, "userName", $$v)},expression:"posDto.userName"}}),_c('EKInputText',{attrs:{"rules":[
                    {
                        type: 'required',
                        message: 'البريد الإلكتروني مطلوب'
                    },
                    {
                        type: 'email',
                        message: 'يجب أن يكون بريد إلكتروني وليس نص'
                    }
                ],"label":"  البريد الالكتروني ","placeholder":"ادخل البريد الالكتروني ","name":"email"},model:{value:(_vm.posDto.email),callback:function ($$v) {_vm.$set(_vm.posDto, "email", $$v)},expression:"posDto.email"}}),_c('EKInputText',{attrs:{"rules":[{ type: 'required', message: 'كلمة السر مطلوب' }],"label":"  كلمة  السر ","placeholder":"ادخل كلمة السر ","name":"password"},model:{value:(_vm.posDto.password),callback:function ($$v) {_vm.$set(_vm.posDto, "password", $$v)},expression:"posDto.password"}}),_c('EKInputText',{attrs:{"rules":[
                    { type: 'required', message: 'رقم الهاتف مطلوب' },
                    {
                        type: 'digits:10',
                        message: 'يجب أن يكون رقم الهاتف عشرة أرقام'
                    }
                ],"label":" رقم الهاتف ","placeholder":"ادخل رقم الهاتف ","name":"phoneNumber"},model:{value:(_vm.posDto.phoneNumber),callback:function ($$v) {_vm.$set(_vm.posDto, "phoneNumber", $$v)},expression:"posDto.phoneNumber"}}),_c('EKInputPicker',{attrs:{"label":"تاريخ الميلاد","rules":[
                    { type: 'required', message: 'تاريخ الميلاد مطلوب' }
                ],"name":"birthday","placeholder":"اختر تاريخ الميلاد"},model:{value:(_vm.posDto.birthday),callback:function ($$v) {_vm.$set(_vm.posDto, "birthday", $$v)},expression:"posDto.birthday"}}),_c('label',{staticClass:"mb-50"},[_vm._v("الجنس")]),_c('div',{staticClass:"d-flex align-items-center mb-1"},[_c('label',{staticClass:"mb-0"},[_vm._v("ذكر")]),_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.posDto.gender),callback:function ($$v) {_vm.$set(_vm.posDto, "gender", $$v)},expression:"posDto.gender"}}),_c('label',{staticClass:"mb-0"},[_vm._v("انثى")])],1),_c('EKInputText',{attrs:{"rules":[
                    {
                        type: 'required',
                        message: 'الحد الاعظمي من المبيعات مطلوب'
                    },
                    {
                        type: 'min_value:0',
                        message: 'يجب ان تكون القيمة موجبة'
                    }
                ],"label":"الحد الاعظمي","placeholder":"ادخل الحد الاعظمي ","name":"moneyLimit","type":"number"},model:{value:(_vm.posDto.moneyLimit),callback:function ($$v) {_vm.$set(_vm.posDto, "moneyLimit", $$v)},expression:"posDto.moneyLimit"}}),_c('EKInputText',{attrs:{"rules":[
                    { type: 'required', message: 'نسبة نقطة البيع مطلوبة' },
                    {
                        type: 'min_value:0',
                        message: 'يجب ان تكون القيمة موجبة'
                    },
                    {
                        type: 'max_value:100',
                        message: 'لا يجب أن تتجاوز القيمة العدد 100'
                    }
                ],"label":"نسبة نقطة البيع","placeholder":"ادخل نسبة نقطة البيع","type":"number","name":"packageDiscountRate"},model:{value:(_vm.posDto.rate),callback:function ($$v) {_vm.$set(_vm.posDto, "rate", $$v)},expression:"posDto.rate"}}),_c('EKInputSelect',{attrs:{"label":"الكليات التابعة لنقطة البيع","placeholder":"كل الكليات","multiple":"","options":_vm.faculties,"name":"facIds"},model:{value:(_vm.posDto.facIds),callback:function ($$v) {_vm.$set(_vm.posDto, "facIds", $$v)},expression:"posDto.facIds"}}),_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.posDto.allowDiscount),callback:function ($$v) {_vm.$set(_vm.posDto, "allowDiscount", $$v)},expression:"posDto.allowDiscount"}},[_vm._v("إمكانية الحسم")]),_c('EKInputSelect',{attrs:{"label":"المدينة","placeholder":"اختر المدينة","options":_vm.citiesList,"rules":[
                    {
                        type: 'required',
                        message: 'المدينة مطلوبة'
                    }
                ],"name":"cityId","clearable":true},model:{value:(_vm.posDto.cityId),callback:function ($$v) {_vm.$set(_vm.posDto, "cityId", $$v)},expression:"posDto.cityId"}}),_c('EKInputTextarea',{attrs:{"rules":[{ type: 'required', message: 'العنوان مطلوب' }],"label":" العنوان ","placeholder":"ادخل العنوان ","name":"address"},model:{value:(_vm.posDto.address),callback:function ($$v) {_vm.$set(_vm.posDto, "address", $$v)},expression:"posDto.address"}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }